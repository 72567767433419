import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

const HowItWorksPage = ({ data }) => (
  <Layout page="how-it-works">

    <Helmet
      title="How our NZ Tours Work | Haka Tours"
      meta={[
        {
          name: 'description', content: 'Our tours are different from everyone else - find out how we give you the NZ trip of a life time',
        },
        { name: 'og:title', content: 'How our NZ Tours Work | Haka Tours' },
        {
          name: 'og:description', content: 'Our tours are different from everyone else - find out how we give you the NZ trip of a life time',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/how-it-works/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/south-island-snow-safari-haka-tours-04.jpg?auto=format">
      <h1 className="c-heading c-heading--h1">How our NZ tours work</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(data.allStaticPages.edges[0].node.content_markdown),
          }}
        />
      </div>
    </section>
  </Layout>
);

HowItWorksPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HowItWorksPage;

export const query = graphql`
  query {
    allStaticPages(
      filter: {
        slug: { eq: "how-it-works" },
      }
    ) {
      edges {
        node {
          id
          api_id
          slug
          content_markdown
        }
      }
    }
  }
`;
